<template>
  <div class="offer-section tw-bg-bg-primary tw-py-8 tw-pl-10 tw-pr-6 md:tw-pr-10">
    <div
      class="offer-section__title tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between lg:tw-items-center tw-mb-4"
    >
      <slot
        name="title"
      />
    </div>
    <slot />
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  /**
   * @module component - OfferSection
   */
  export default defineComponent({})
</script>
