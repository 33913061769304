<template>
  <div
    v-if="isAvailableForBooking"
    class="offer-booking-footer tw-flex tw-justify-center tw-items-center tw-bg-bg-primary tw-py-6 tw-bottom-0"
  >
    <ui-button
      class="tw-px-6"
      variant="primary"
      size="lg"
      data-test="book-offer"
      @click="book"
    >
      {{ $t('booking_offer_title') }}
    </ui-button>
  </div>
</template>

<script>
  import { computed, defineComponent, toRefs } from '@vue/composition-api'

  import useMatomo from '@/composables/useMatomo'
  import { EventBus } from '@/services/EventBus'

  export default defineComponent({
    props: {
      offer: {
        type: Object,
        required: true
      }
    },
    setup (props) {
      const { offer } = toRefs(props)
      const matomo = useMatomo()

      const isAvailableForBooking = computed(() => {
        if (typeof offer.value.available_for_booking === 'undefined') return true
        if (offer.value.available_for_booking) return true

        return false
      })

      function book () {
        if (matomo) {
          matomo.trackEvent('Offers', 'Initiated Booking', offer.value.uuid)
        }

        EventBus.$emit('toggle-booking-modal')
      }

      return {
        book,
        isAvailableForBooking
      }
    }
  })
</script>

<style lang="scss" scoped>

  .offer-booking-footer {
    border-top: 1px solid $gray;
  }

</style>
